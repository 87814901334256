import {
  BooleanFeatureToggle,
  useRealtimeFeatureToggle,
} from "../shared/contexts/FeatureToggleContex/FeatureToggleContext";

export const ListingsSummaryFeatureToggle =
  new BooleanFeatureToggle<"listings-summary_temp_20220103">("listings-summary_temp_20220103");

export const useListingSummaryFeatureToggle = () =>
  useRealtimeFeatureToggle(ListingsSummaryFeatureToggle);

export const AIAssistedTextFeatureToggle = new BooleanFeatureToggle(
  "ai-assisted-text_temp_20230523" as const
);

export const useAIAssistedTextFeatureToggle = () =>
  useRealtimeFeatureToggle(AIAssistedTextFeatureToggle);
