import * as React from "react";
import {
  CloseIcon,
  Button,
  styled,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Select,
  SelectOption,
  Fieldset,
  Checkbox,
} from "@avenue-8/ui-2";
import { DynamicDialog } from "src/modules/cma-v2/components/DynamicDialog";
import { defaultTextEditorContentStyle } from "src/modules/shared/components/TextEditor/TextEditor.styles";
import { getCMAPresentationApi } from "src/modules/shared/apis/presentation/api-factories";
import { AITextGenerator } from "./ai-text-generator";
import { usePresentationEditorLogic } from "../../../../usePresentationEditorLogic";
import { subjectPropertyDescriptionGenerator } from "./generators/subject-property-description-generator";
import { OpenAIIcon } from "./OpenAIIcon";
import { InputLabel, Typography } from "@mui/material";
import { areaOverviewGenerator } from "./generators/area-overview-generator";
import { addEmptyParagraphsBetweenConsecutiveParagraphs, centralizeFirstHeader, sanitizeOpenAIHtmlOutput } from "./utils";
import { Timer } from "./Timer";
import { anySubjectContentGenerator } from "./generators/any-subject-content-generator";
import { subjectPropertyTopicInfoGenerator } from "./generators/subject-property-topic-info-generator";
import { toast } from "react-toastify";
import { CancelController, PromiseCanceledError, makeCancelablePromise } from "./cancelable-promise";

const FreeTextHtmlContent = styled.div`
  padding: 2rem;
  box-shadow: inset 0px 0px 2px #3838686d;
  overflow-y: auto;
  border-radius: 4px;

  ${() => defaultTextEditorContentStyle}
`;

interface TextGeneratorProps {
  open: boolean;
  onClose: () => void;
  onClosed?: () => void;
  defaultValues: {
    htmlContent: string;
  };
  onSave: (data: { htmlContent: string }) => void;
}

export const FormDivider = styled.div`
  height: 1rem;
`;

const generators: Array<AITextGenerator<any>> = [
  anySubjectContentGenerator,
  // loremGenerator,
  areaOverviewGenerator,
  subjectPropertyTopicInfoGenerator,
  subjectPropertyDescriptionGenerator,
].sort((a, b) => a.title.localeCompare(b.title));

const CloseButton = styled(IconButton)`
  position: absolute;
  right: 8px;
  top: 12px;
`;

function AIDisclaimer() {
  return (<Typography
    color="textSecondary"
    variant="caption">
    <span style={{ fontStyle: "italic" }}>Disclaimer:</span>
    &nbsp;AI-generated output may not be fully accurate. Cross-verify before using the information.
  </Typography>);
}

const TextGeneratorModalInner = ({
  onSave,
  defaultValues,
  onClose,
  onClosed,
  ...rest
}: TextGeneratorProps) => {
  const { state } = usePresentationEditorLogic();
  const { htmlContent: initialHtmlContent } = defaultValues;
  const [confirmCloseDialogOpen, setConfirmCloseDialogOpen] = React.useState(false);
  const [isDirty, setIsDirty] = React.useState(false);
  const [generator, setGenerator] = React.useState<AITextGenerator<any>>(generators[0]);
  const formValue = React.useRef<any>(generators[0].formInitState);
  const [htmlContent, setHtmlContent] = React.useState(initialHtmlContent);
  const [isGenerating, setIsGenerating] = React.useState(false);
  const prompts = React.useRef<string[]>([]);
  const [showPrompt, setShowPrompt] = React.useState(false);
  const cancelController = React.useRef<CancelController>();

  const handleSave = async () => {
    onSave({ htmlContent });
    setIsDirty(false);
  };

  const handleSaveAndClose = async () => {
    await handleSave();
    onClose();
  };

  const handleCloseWithPrompt = async () => {
    if (isGenerating) {
      cancelController.current?.cancel();
    }
    if (isDirty) {
      setConfirmCloseDialogOpen(true);
      return;
    }
    setConfirmCloseDialogOpen(false);
    onClose();
  };

  const handleConfirmDialogClose = () => {
    setConfirmCloseDialogOpen(false);
    onClose();
  };

  const handleConfirmDialogSave = async () => {
    handleSave();
    setConfirmCloseDialogOpen(false);
    onClose();
  };

  if (state.loadState !== 'loaded') return null;

  const Form = generator.Form;
  const context = {
    source: state.sourceData
  }

  const handleChangeGenerator = (e: { target: { value: string } }) => {
    const newGenerator = generators.find(g => g.key === e.target.value)!;
    formValue.current = newGenerator.formInitState;
    setGenerator(newGenerator);
  }

  const handleSubmitGenerate = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (isGenerating) return;

    const error = generator.validateRequirements(context);
    if (error) {
      toast.error(`Your presentation does not meet the requirements.\nReason: ${error}`);
      return;
    }

    setIsGenerating(true);
    prompts.current = [];
    try {
      const generatePromise = generator.generate({
        context,
        completeText: async (text, temperature = 0) => {
          prompts.current.push(text);
          const result = await getCMAPresentationApi().cMAPresentationControllerGetAnswerFromAssistant({
            assistantPrompt: {
              prompt: text,
              temperature
            }
          });
          return result.output;
        },
        formData: formValue.current
      });
      const { controller, promise } = makeCancelablePromise(generatePromise);
      cancelController.current = controller;
      let htmlContent = await promise;
      htmlContent = addEmptyParagraphsBetweenConsecutiveParagraphs(htmlContent);
      htmlContent = centralizeFirstHeader(htmlContent);
      htmlContent = sanitizeOpenAIHtmlOutput(htmlContent);
      setHtmlContent(htmlContent);
      setIsGenerating(false);
      if (!isDirty) setIsDirty(true);
    }
    catch (e) {
      if (!(e instanceof PromiseCanceledError)) {
        console.error(e);
        toast.error('Failed to generate content. Please try again.');
      }
      setIsGenerating(false);
    }
  };

  return (
    <>
      <Dialog fullWidth maxWidth="md" {...rest}
        onClose={handleCloseWithPrompt} disableEnforceFocus
        TransitionProps={{
          onExited: onClosed
        }}
      >
        <DialogTitle id="editor-modal">
          Content Generator
          <CloseButton aria-label="close" onClick={handleCloseWithPrompt}>
            <CloseIcon />
          </CloseButton>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmitGenerate}>
            <Fieldset disabled={isGenerating}>
              <InputLabel>Generator</InputLabel>
              <Select title="Generator" value={generator.key} onChange={handleChangeGenerator}>
                {generators.map((generator) => (
                  <SelectOption key={generator.key} value={generator.key}>
                    {generator.title}
                  </SelectOption>)
                )}
              </Select>
              <FormDivider />
              <Typography variant="body2">{generator.description}</Typography>
              <FormDivider />
              <Form context={context} formInitState={generator.formInitState} onFormChanged={(value) => {
                formValue.current = value;
              }} />
            </Fieldset>
            <FormDivider />
            <Button disabled={isGenerating} type="submit">
              {isGenerating ? "Generating..." : "Generate"}
            </Button>
            <>&nbsp;</><>&nbsp;</>
            <OpenAIIcon size={32} spinning={isGenerating} />
            <>&nbsp;</><>&nbsp;</>
            <Timer
              tick={1000} status={isGenerating ? "running" as const : "stopped" as const}
              render={(time, status) => (<>
                {(time > 0 || status === "running") && <Typography variant="caption">{status === "stopped" ? "took " : ""}{time / 1000} seconds</Typography>}
              </>)}
            />
            {isGenerating && (<>
              <>&nbsp;</><>&nbsp;</><Button size="small" variant="text" onClick={() => {
                if (cancelController.current) {
                  cancelController.current.cancel();
                  setIsGenerating(false);
                }
              }}>Abort</Button>
            </>)}
          </form>
          <FormDivider />
          <Typography variant="h6">Preview</Typography>
          <FreeTextHtmlContent style={{ opacity: isGenerating ? 0.5 : 1 }} dangerouslySetInnerHTML={{
            __html: htmlContent
          }} />
          <FormDivider />
          <AIDisclaimer />
          <FormDivider />
          <InputLabel>Show OpenAI Prompt</InputLabel>
          <Checkbox checked={showPrompt} onChange={(e) => setShowPrompt(e.target.checked)} />
          {!isGenerating && showPrompt && <pre style={{ fontSize: 11, wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
            {prompts.current.map((prompt, index) => (
              <React.Fragment key={index}>
                {prompt}
                <br />
                <br />
              </React.Fragment>
            ))}
          </pre>}
          <div style={{ height: "1rem" }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWithPrompt} variant="text">
            Cancel
          </Button>
          <Button autoFocus disabled={!isDirty} onClick={handleSaveAndClose}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <DynamicDialog
        title={"Do you want to save?"}
        message={"You have made changes that have not been saved."}
        open={confirmCloseDialogOpen}
        actions={[
          {
            label: "No, discard",
            onClick: () => handleConfirmDialogClose(),
          },
          {
            label: "Save",
            onClick: async () => handleConfirmDialogSave(),
            primary: true,
          },
        ]}
      />
    </>
  );
};

// This wrapper is meant to reset the component internal state when it closes
export function TextGeneratorModal(props: TextGeneratorProps) {
  const [closeCount, setCloseCount] = React.useState(0);
  return (<TextGeneratorModalInner {...props} onClosed={() => {
    props.onClosed?.();
    setCloseCount(closeCount + 1);
  }} key={closeCount} />);
}