import * as React from "react";
import { PresentationEditorLayout } from "./PresentationEditorLayout";
import { usePresentationEditorLogic } from "./usePresentationEditorLogic";
import { PresentationView } from "./../../../PresentationView/PresentationView";
import { useDisplayMode } from "../../../../hooks/useMobile";
import { DevicePicker } from "./DevicePicker";
import { SidebarLayout } from "./components/SidebarLayout";
import { Typography, Loader, styled } from "@avenue-8/ui-2";
import { usePresentationTemplate } from "./usePresentationTemplate";
import { TemplateListItemDto } from "../../../../../shared/apis/presentation/generated";
import { useAuthContext } from "../../../../../shared/contexts/AuthContext/AuthContext";

const SidebarContainer = styled.div<{ $shrink: boolean }>`
  position: sticky;
  top: ${(p) => (p.$shrink ? "78px" : "155px")};
  max-height: ${(p) => (p.$shrink ? "calc(100vh - 48px)" : "calc(100vh - 192px)")};
  z-index: 10;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const MessageContainer = styled.div`
  width: 100%;
  padding: 80px 24px 72px 24px;
  text-align: center;
`;

export const PresentationEditorStep = ({ id: _id }: { id: string }) => {
  const {
    actions: {
      addFreeTextSection,
      addInsightsSection,
      addTeamProfileSection: _addTeamProfileSection,
      addAIAssistedTextSection,
      toggleSectionVisibility,
      setDisplayMode,
      moveSection,
      updateSection,
      setTemplateId,
      deleteSection,
    },
    state,
  } = usePresentationEditorLogic();
  const {
    state: { user },
  } = useAuthContext();

  const templateId = state.loadState === "loaded" ? state.templateId : null;
  const { listTemplatesQuery, deleteTemplateMutation } = usePresentationTemplate({ templateId });
  const displayMode = useDisplayMode();
  const mobileDisplay = displayMode === "mobile";
  const shrinkSidebar = displayMode === "desktop";
  const smartDisplayMode = mobileDisplay ? "mobile" : state.displayMode ?? "responsive";

  // TODO: Improve this team section to be more flexible
  const addTeamProfileSection = React.useMemo(() => {
    if (user && user.email?.includes("avenue8.com")) {
      return _addTeamProfileSection;
    }
    return undefined;
  }, [user, _addTeamProfileSection]);

  if (state.loadState === "failed")
    return (
      <MessageContainer>
        <Typography variant="h6">Failed to load the presentation</Typography>
      </MessageContainer>
    );
  if (state.loadState === "not-loaded")
    return (
      <MessageContainer>
        <Loader />
      </MessageContainer>
    );

  const sectionsConfigs = state.sections.map((x) => x.config);
  const sectionsViews = state.presentationView.sections;

  const handleSectionExpanded = (id?: string) => {
    if (!id) return;
    const element = document.getElementById(`section_${id}`);
    if (!element) return;
    const y = element.getBoundingClientRect().top + window.pageYOffset - 110;
    window.scrollTo({ top: y, behavior: "smooth" });
  };

  const sidebar = (
    <SidebarContainer $shrink={shrinkSidebar}>
      <SidebarLayout
        onMoveSection={moveSection}
        onToggleSectionVisibility={toggleSectionVisibility}
        onUpdateSection={updateSection}
        sectionsConfig={sectionsConfigs}
        sectionsView={sectionsViews}
        templates={listTemplatesQuery.data?.items}
        handleSelectTemplate={(templateId: string) => {
          setTemplateId(templateId);
        }}
        handleDeleteTemplate={(template: TemplateListItemDto) => {
          deleteTemplateMutation.mutate(template);
        }}
        onAddFreeTextSection={addFreeTextSection}
        onAddInsightsSection={addInsightsSection}
        onAddTeamProfileSection={addTeamProfileSection}
        onAddAIAssistedTextSection={addAIAssistedTextSection}
        onDeleteSection={deleteSection}
        templateId={templateId}
        onSectionExpanded={handleSectionExpanded}
        sourceData={state.sourceData}
      />
      {!mobileDisplay && (
        <DevicePicker
          value={smartDisplayMode}
          onValueChange={(m) => setDisplayMode(m || "mobile")}
        />
      )}
    </SidebarContainer>
  );

  const preview = (
    <PresentationView
      key={smartDisplayMode}
      sections={sectionsViews}
      presentationId={state.id}
      client={state.presentationView.client}
      agent={state.presentationView.agent}
      editMode={true}
      renderNav={true}
      displayMode={smartDisplayMode}
      showDivider={true}
      subjectProperty={state.presentationView.subjectProperty}
      galleries={state.presentationView.galleries}
      priceRange={state.presentationView.priceRange}
      updatedAt={state.presentationView.updatedAt}
    />
  );

  return (
    <PresentationEditorLayout
      sidebar={sidebar}
      preview={preview}
      maxFrameWidth={mobileDisplay === false && state.displayMode === "mobile" ? 500 : undefined}
    />
  );
};
