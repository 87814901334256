import * as React from "react";
import { Button } from "@avenue-8/ui-2";
import { FreeHtmlTextSectionConfig } from "src/modules/presentation/presentation-generation-logic/models/section-config";
import { SectionEditorProps } from "../../SectionEditor";
import { TextGeneratorModal } from "./TextGeneratorModal";
import { TextEditorModal } from "./TextEditorModal";

export const AIAssistedTextSectionEditor = ({
  section,
  onChanged,
  onDelete,
}: SectionEditorProps<FreeHtmlTextSectionConfig>) => {
  const [editorModalOpen, setEditorModalOpen] = React.useState(false);
  const [generatorModalOpen, setGeneratorModalOpen] = React.useState(false);
  const [model, setModel] = React.useState({
    title: section.title,
    htmlContent: section.htmlContent,
  });
  const handleSave = React.useCallback(
    ({ htmlContent }: { htmlContent: string }) => {
      const updatedModel = { ...model, htmlContent };
      setModel(updatedModel);
      onChanged({ sectionConfig: { ...section, ...updatedModel } });
      setEditorModalOpen(false);
    },
    [model, onChanged, section]
  ); 

  return (
    <>
      <Button variant="text" onClick={() => setGeneratorModalOpen(true)}>
        Generate Content
      </Button>
      <Button variant="text" onClick={() => setEditorModalOpen(true)}>
        Edit Section
      </Button>
      <Button variant="text" onClick={() => onDelete(section.id)}>
        Delete Section
      </Button>

      <TextEditorModal
        defaultValues={{
          htmlContent: model.htmlContent,
        }}
        open={editorModalOpen}
        onClose={() => setEditorModalOpen(false)}
        onSave={handleSave}
      />
      <TextGeneratorModal
        defaultValues={{
          htmlContent: model.htmlContent,
        }}
        open={generatorModalOpen}
        onClose={() => setGeneratorModalOpen(false)}
        onSave={handleSave}
      />
    </>
  );
};
