import { InputLabel, Select, TextField } from "@mui/material";
import { AITextGenerator, AITextGeneratorForm } from "../ai-text-generator";
import { useState } from "react";
import { SelectOption } from "@avenue-8/ui-2";
import { FormDivider } from "../TextGeneratorModal";

interface FormValues {
  topic: string;
  numberOfParagraphs: string;
}

const Form: AITextGeneratorForm<FormValues> = ({
  onFormChanged,
  formInitState
}) => {

  const [formValues, setFormValues] = useState<FormValues>(formInitState);

  const handleInputChange = (event: { target: { name: string, value: string } }) => {
    const { name, value } = event.target;
    const newFormValues = {
      ...formValues,
      [name as string]: value,
    };
    setFormValues(newFormValues);
    onFormChanged(newFormValues);
  }

  return (<>
    <TextField
      name="topic"
      value={formValues.topic}
      label="topic"
      fullWidth
      onChange={handleInputChange}
      helperText="The topic you want to generate content about."
    />
    <FormDivider />
    <InputLabel>Content Size</InputLabel>
    <Select
      name="numberOfParagraphs"
      value={formValues.numberOfParagraphs}
      label="Content Size"
      onChange={handleInputChange}
    >
      <SelectOption value={"free"}>Choose for me</SelectOption>
      <SelectOption value={1}>Concise</SelectOption>
      <SelectOption value={2}>Small</SelectOption>
      <SelectOption value={3}>Medium</SelectOption>
      <SelectOption value={4}>Large</SelectOption>
    </Select>
  </>);
}

export const subjectPropertyTopicInfoGenerator: AITextGenerator<FormValues> = {
  title: 'Subject Property Topic Info Generator',
  key: 'subject-property-topic-info',
  description: 'Ask something about the subject property to generate a description about it.',
  validateRequirements: (context) => {
    if (context.source.presentationType !== "cma") {
      return "Your presentation is not a CMA.";
    }
    return null;
  },
  Form,
  formInitState: {
    numberOfParagraphs: "free",
    topic: "How well the property is located?",
  },
  generate: async ({ completeText, context, formData }) => {
    const property = context.source.featuredProperty;
    delete property.photos;
    return completeText(`
Your objective is to answer a question about a property. The property data is below."

Property data:
"""
${JSON.stringify(property)}
"""

The question is:
"""
${formData.topic}
"""

Now, write a straightforward answer to the question.
${formData.numberOfParagraphs !== "free" ? `Use ${formData.numberOfParagraphs} paragraphs.` : 'If there is a lot to write, use paragraphs to make the description more readable.'}
Add a descriptive title at the top too. The title can be based on the question.
The output should be in HTML format, don't include any CSS styles and use only the following tags: 
- h1
- p

HTML:`, 0.3)

  }
}