import { InputLabel, Select, TextField } from "@mui/material";
import { AITextGenerator, AITextGeneratorForm } from "../ai-text-generator";
import { useState } from "react";
import { SelectOption } from "@avenue-8/ui-2";
import { FormDivider } from "../TextGeneratorModal";

interface FormValues {
  subject: string;
  numberOfParagraphs: string;
}

const Form: AITextGeneratorForm<FormValues> = ({
  onFormChanged,
  formInitState
}) => {

  const [formValues, setFormValues] = useState<FormValues>(formInitState);

  const handleInputChange = (event: { target: { name: string, value: string } }) => {
    const { name, value } = event.target;
    const newFormValues = {
      ...formValues,
      [name as string]: value,
    };
    setFormValues(newFormValues);
    onFormChanged(newFormValues);
  }

  return (<>
    <TextField
      name="subject"
      value={formValues.subject}
      label="Subject"
      fullWidth
      onChange={handleInputChange}
    />
    <FormDivider />
    <InputLabel>Content Size</InputLabel>
    <Select
      name="numberOfParagraphs"
      value={formValues.numberOfParagraphs}
      label="Content Size"
      onChange={handleInputChange}
    >
      <SelectOption value={"free"}>Choose for me</SelectOption>
      <SelectOption value={1}>Concise</SelectOption>
      <SelectOption value={2}>Small</SelectOption>
      <SelectOption value={3}>Medium</SelectOption>
      <SelectOption value={4}>Large</SelectOption>
    </Select>
  </>);
}

export const anySubjectContentGenerator: AITextGenerator<FormValues> = {
  title: 'Any Subject Content Generator',
  key: 'any-subject-content',
  description: 'Generate a content about any subject.',
  validateRequirements: () => null,
  formInitState: {
    numberOfParagraphs: "free",
    subject: "The geography of New York City",
  },
  Form,
  generate: async ({ completeText, context, formData }) => {
    const property = context.source.featuredProperty;
    delete property.photos;
    return completeText(`
Write a text about the following subject: "${formData.subject}".
${formData.numberOfParagraphs !== "free" ? `Use ${formData.numberOfParagraphs} paragraphs.` : 'Use paragraphs to make the description more readable.'}
Add a descriptive title at the top too.
The output should be in HTML format, don't include any CSS styles and use only the following tags: 
- h1
- p

HTML:`, 0.5)

  }
}