export class PromiseCanceledError extends Error {
  constructor(message = "Promise canceled") {
    super(message);
    this.name = "CancelError";
  }
}

export type CancelController = {
  cancel: () => void;
};

/**
 * Wraps a promise in a cancelable promise.
 */
export function makeCancelablePromise<T>(promise: Promise<T>): {
  promise: Promise<T>;
  controller: CancelController;
} {
  const controller: CancelController = {
    cancel: () => {},
  };

  const wrappedPromise = Promise.race([
    promise,
    new Promise<T>((_, reject) => {
      controller.cancel = () => {
        reject(new PromiseCanceledError());
        controller.cancel = () => {};
      };
    }),
  ]) as Promise<T>;

  return {
    promise: wrappedPromise,
    controller,
  };
}
