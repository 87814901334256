import {
  SectionConfig,
  SectionConfigBase,
} from "../../../../../../../presentation/presentation-generation-logic/models/section-config";
import { SideBySideComparisonEditor } from "./components/SideBySideComparisonEditor";
import { InsightsEditor } from "./components/InsightsEditor";
import { KeyFeaturesSectionEditor } from "./components/KeyFeaturesSectionEditor";
import { styled } from "@avenue-8/ui-2";
import { FreeTextEditor } from "./components/FreeTextEditor";
import { HeaderSectionEditor } from "./components/HeaderSectionEditor";
import { GeneralHeaderSectionEditor } from "./components/GeneralHeaderSectionEditor";
import { PresentationSourceData } from "src/modules/presentation/presentation-generation-logic";
import { FreeHtmlTextEditor } from "./components/FreeHtmlTextEditor";
import { TeamProfileEditor } from "./components/TeamProfileEditor";
import { AIAssistedTextSectionEditor } from "./components/AIAssistedTextSectionEditor";

const SectionEditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

type PartialSourceData = {
  priceRange?: Partial<PresentationSourceData["priceRange"]>;
  coverPhotoCdnUrl?: PresentationSourceData["coverPhotoCdnUrl"];
};
export type SectionEditorOnChangeHandler = (params: {
  sectionConfig: SectionConfig;
  partialSourceData?: PartialSourceData;
}) => void;
export interface SectionEditorProps<T extends SectionConfigBase> {
  section: T;
  onChanged: SectionEditorOnChangeHandler;
  onDelete: (id: string) => void;
  sourceData: PresentationSourceData;
}

type ComponentType = (props: SectionEditorProps<any>) => any;

const DefaultComponent: ComponentType = () => {
  return <div style={{ fontSize: 14 }}>No customizations available</div>;
};

function getEditorComponent(type: SectionConfig["type"]): ComponentType {
  switch (type) {
    case "insights":
      return InsightsEditor;
    case "key-features":
      return KeyFeaturesSectionEditor;
    case "side-by-side-comparision":
      return SideBySideComparisonEditor;
    case "free-text":
      return FreeTextEditor;
    case "ai-assisted-text":
      return AIAssistedTextSectionEditor;
    case "header":
      return HeaderSectionEditor;
    case "general-header":
      return GeneralHeaderSectionEditor;
    case "agent-info":
      return () => null;
    case "free-html-text":
      return FreeHtmlTextEditor;
    case "team-profile":
      return TeamProfileEditor;
    default:
      return DefaultComponent;
  }
}


export const SectionEditor = (props: SectionEditorProps<SectionConfig>) => {
  const { section } = props;
  const Component = getEditorComponent(section.type);
  return (
    <SectionEditorContainer data-testid={`presentation-section-editor-${section.type}`}>
      <Component {...props} />
      {/* <div><pre><code>{JSON.stringify(section, null, ' ')}</code></pre></div> */}
    </SectionEditorContainer>
  );
};
