import React from "react";
import { ConstrainedContainer } from "./ConstrainedContainer";
import { TeamProfileSectionView } from "../../../../presentation/presentation-generation-logic/models/section-view";
import { SectionViewLayout } from "./SectionViewLayout";
import { AvoidPrintBreakInside } from "./AvoidPrintBreakInside";
import { Title1 } from "./typography";
import { SmallDivider } from "./SmallDivider";
import { styled, Grid, Typography } from "@avenue-8/ui-2";

interface Props extends TeamProfileSectionView {}

const TeamContainer = styled.div`
  display: flex;
  margin: 2rem auto 0;
  align-items: center;
  justify-content: center;
  max-width: 480px;
  gap: 2rem 6rem;
  flex-wrap: wrap;
`;

const TeamInfo = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
`;

const ImageWrapper = styled(Grid)`
  img {
    width: 100%;
    max-width: 190px;
  }
`;

export const TeamProfile = (props: Props) => {
  const { id, title, name, slug, logo, licenseNumber, phoneNumber, website } = props;

  const hasTeamInfo = !!name && !!slug && !!logo;

  const teamProfileContent = hasTeamInfo ? (
    <>
      <ImageWrapper>
        <a href={website ?? "#"} target="_blank" rel="noreferrer">
          <img src={`/images/team-profiles/${logo}`} alt={name} />
        </a>
      </ImageWrapper>
      <TeamInfo>
        {!!phoneNumber && <Typography variant="h6">{phoneNumber}</Typography>}
        {!!licenseNumber && <Typography variant="h6">DRE# {licenseNumber}</Typography>}
      </TeamInfo>
    </>
  ) : (
    <Typography variant="body2">
      You need to select a team to display its information on the presentation.
    </Typography>
  );

  return (
    <SectionViewLayout>
      <AvoidPrintBreakInside>
        <ConstrainedContainer id={id} $textAlign="center">
          <Title1 marginBottom={1}>{title ?? "The Team"}</Title1>
          <SmallDivider />
          <TeamContainer>{teamProfileContent}</TeamContainer>
        </ConstrainedContainer>
      </AvoidPrintBreakInside>
    </SectionViewLayout>
  );
};
