import * as React from "react";
import { ConstrainedContainer } from "../ConstrainedContainer";
import { Title1 } from "../typography";
import { KeyFeaturesSectionView } from "../../../../../presentation/presentation-generation-logic/models";
import { SectionViewLayout } from "../SectionViewLayout";
import { BreakdownPopover } from "../BreakdownPopover";
import { PrintOff } from "../PrintOff";
import {
  Container,
  ItemLabel,
  ItemValue,
  BedBathsItem,
  LabelWrapper,
} from "./KeyFeatures.styles";

interface Props extends KeyFeaturesSectionView {
  mobile?: boolean;
}

export const KeyFeatures = (props: Props) => {
  const {
    title,
    propertyType,
    yearBuilt,
    id,
    neighborhood,
    livingSpace,
    lotSize,
    baths,
    beds,
    garageParkingSpaces,
    costPerInternalSqFt,
    listPrice,
    soldPrice,
    roomsTotal,
    flipTaxRemarks,
    recurringCosts,
    totalMonthlies,
    hasDoormanAttendance,
    preferences: p,
  } = props;
  return (
    <SectionViewLayout>
      <ConstrainedContainer id={id}>
        <Title1 textAlign="center" pageBreakBefore>
          {title}
        </Title1>
        {/* <Description variant="body2">{description}</Description> */}
        <br />

        <Container data-testid="key-features-container">
          {(p == null || p.showPropertyType === true) && (
            <div data-testid="property-type">
              <ItemLabel>Property Type</ItemLabel>
              <ItemValue>{propertyType ?? "-"}</ItemValue>
            </div>
          )}
          {(p == null || p.showListPrice === true) && (
            <div data-testid="list-price">
              <ItemLabel>List Price</ItemLabel>
              <ItemValue>{listPrice ? `$${listPrice?.toLocaleString("en-US")}` : "-"}</ItemValue>
            </div>
          )}
          {(p == null || p.showSoldPrice === true) && (
            <div data-testid="sold-price">
              <ItemLabel>Sold Price</ItemLabel>
              <ItemValue>{soldPrice ? `$${soldPrice?.toLocaleString("en-US")}` : "-"}</ItemValue>
            </div>
          )}
          {(p == null || p.showYearBuilt === true) && (
            <div data-testid="year-built">
              <ItemLabel>Year Built</ItemLabel>
              <ItemValue>{yearBuilt ?? "-"}</ItemValue>
            </div>
          )}
          {(p == null || p.showNeighborhood === true) && (
            <div data-testid="neighborhood ">
              <ItemLabel>Neighborhood</ItemLabel>
              <ItemValue>{neighborhood ?? "-"}</ItemValue>
            </div>
          )}
          {(p == null || p.showLivingSpace === true) && (
            <div data-testid="living-space">
              <ItemLabel>Living Space</ItemLabel>
              <ItemValue>
                {livingSpace?.toLocaleString("en-US") ?? "-"}
                {livingSpace ? " sq. ft." : ""}
              </ItemValue>
            </div>
          )}
          {(p == null || p.showBeds === true || p.showBaths === true) && (
            <BedBathsItem>
              {(p == null || p.showBeds === true) && (
                <div data-testid="beds">
                  <ItemLabel>Beds</ItemLabel>
                  <ItemValue>{beds ?? "-"}</ItemValue>
                </div>
              )}
              {(p == null || p.showBaths === true) && (
                <div data-testid="baths">
                  <ItemLabel>Baths</ItemLabel>
                  <ItemValue>{baths ?? "-"}</ItemValue>
                </div>
              )}
            </BedBathsItem>
          )}
          {(p == null || p.showRoomsTotal === true) && (
            <div data-testid="total-rooms">
              <ItemLabel>Total Rooms</ItemLabel>
              <ItemValue>{roomsTotal ?? "-"}</ItemValue>
            </div>
          )}
          {(p == null || p.showLotSize === true) && (
            <div data-testid="lot-size">
              <ItemLabel>Lot size</ItemLabel>
              <ItemValue>
                {lotSize?.toLocaleString("en-US") ?? "-"}
                {lotSize ? " sq. ft." : ""}
              </ItemValue>
            </div>
          )}
          {(p == null || p.showGarageParkingSpaces === true) && (
            <div data-testid="garage-parking-spaces">
              <ItemLabel>Garage Parking Spaces</ItemLabel>
              <ItemValue>{garageParkingSpaces ?? "-"}</ItemValue>
            </div>
          )}
          {(p == null || p.showRecurringCosts === true) && (
            <div data-testid="monthlies">
              <LabelWrapper>
                <ItemLabel>Monthlies</ItemLabel>{" "}
                <PrintOff>
                  {Boolean(totalMonthlies) && <BreakdownPopover fees={recurringCosts ?? []} />}
                </PrintOff>
              </LabelWrapper>
              <ItemValue>
                {totalMonthlies ? `$${totalMonthlies.toLocaleString("en-US")}` : "-"}
              </ItemValue>
            </div>
          )}
          {(p == null || p.showPricePerLivingSpaceSqft === true) && (
            <div data-testid="price-per-living-space">
              <ItemLabel>Price Per Living Space Sq. Ft.</ItemLabel>
              <ItemValue>
                {costPerInternalSqFt ? `$${costPerInternalSqFt.toLocaleString("en-US")}` : "-"}
              </ItemValue>
            </div>
          )}
          {(p == null || p.showDoormanAttendance === true) && (
            <div data-testid="doorman">
              <ItemLabel>Doorman / Attendance</ItemLabel>
              <ItemValue>
                {typeof hasDoormanAttendance === "boolean"
                  ? hasDoormanAttendance
                    ? "Yes"
                    : "No"
                  : "-"}
              </ItemValue>
            </div>
          )}
          {(p == null || p.showFlipTaxRemarks === true) && (
            <div data-testid="flip-tax">
              <ItemLabel>Flip Tax</ItemLabel>
              <ItemValue>{flipTaxRemarks ?? "-"}</ItemValue>
            </div>
          )}
        </Container>
      </ConstrainedContainer>
    </SectionViewLayout>
  );
};