import { InputLabel, Select } from "@mui/material";
import { AITextGenerator, AITextGeneratorForm } from "../ai-text-generator";
import { useState } from "react";
import { SelectOption } from "@avenue-8/ui-2";
import { FormDivider } from "../TextGeneratorModal";

interface FormValues {
  targetArea: string;
  customerType: string;
  textSize: string;
}

const Form: AITextGeneratorForm<FormValues> = ({ onFormChanged, context, formInitState }) => {

  const [formValues, setFormValues] = useState<FormValues>(formInitState);

  const handleInputChange = (event: { target: { name: string, value: string } }) => {
    const { name, value } = event.target;
    const newFormValues = {
      ...formValues,
      [name as string]: value,
    };
    setFormValues(newFormValues);
    onFormChanged(newFormValues);
  }

  const subjectProperty = context.source.featuredProperty;

  return (
    <>
      <InputLabel>Target Area</InputLabel>
      <Select
        name="targetArea"
        value={formValues.targetArea}
        label="Target Area"
        onChange={handleInputChange}
      >
        <SelectOption value={"most-specific"}>Most Specific</SelectOption>
        {subjectProperty.state && <SelectOption value={"state"}>State: {subjectProperty.state}</SelectOption>}
        {subjectProperty.city && <SelectOption value={"city"}>City: {subjectProperty.city}</SelectOption>}
        {subjectProperty.neighborhood && <SelectOption value={"neighborhood"}>Neighborhood: {subjectProperty.neighborhood}</SelectOption>}
      </Select>
      <FormDivider />
      <InputLabel>Customer Type</InputLabel>
      <Select
        name="customerType"
        value={formValues.customerType}
        label="Customer Type"
        onChange={handleInputChange}
      >
        <SelectOption value={"generic"}>Generic</SelectOption>
        <SelectOption value={"Investor buying a property"}>Investor buying a property</SelectOption>
        <SelectOption value={"Young couple buying a home"}>Young Couple buying a home</SelectOption>
        <SelectOption value={"Family buying a home"}>Family buying a home</SelectOption>
        <SelectOption value={"Retired couple buying a home"}>Retired couple buying a home</SelectOption>
        <SelectOption value={"Student leasing a home"}>Student leasing a home</SelectOption>
      </Select>
      <FormDivider />
      <InputLabel>Content Size</InputLabel>
      <Select
        name="textSize"
        value={formValues.textSize}
        label="Content Size"
        onChange={handleInputChange}
      >
        <SelectOption value={"100 words"}>Concise</SelectOption>
        <SelectOption value={"200 words"}>Small</SelectOption>
        <SelectOption value={"400 words"}>Medium</SelectOption>
        <SelectOption value={"800 words"}>Large</SelectOption>
      </Select>
    </>
  )
}

export const areaOverviewGenerator: AITextGenerator<FormValues> = {
  title: 'Real Estate Market Overview Generator',
  key: 'area-overview',
  validateRequirements: (context) => {
    if (context.source.presentationType !== "cma") {
      return "Your presentation is not a CMA.";
    }
    return null;
  },
  description: 'Generate a concise yet informative overview of the real estate market in the area surrounding the subject property. Use only data from OpenAI (2021 and before).',
  formInitState: {
    targetArea: "most-specific",
    customerType: "generic",
    textSize: "200 words",
  },
  Form,
  generate: async ({ completeText, context, formData }) => {
    const property = context.source.featuredProperty;
    const { state, city, neighborhood, addressLine1 } = property;
    const location = { state, city, neighborhood, addressLine1 };
    let promptInit: string;

    if (formData.targetArea === 'most-specific') {
      promptInit = `The following JSON contains the location of a listing (real estate).

JSON:
'''
${JSON.stringify(location)}
'''
Based on the information regarding the property's location, write a text about the real estate market in the area.
Use the most specific information available.
For example:
  - Prefer to write about the neighborhood instead of the city.
  - Prefer to write about the city instead of the state.
    `;
    }
    else {
      promptInit = `Write a text about the real estate market in the following area: ${formData.targetArea}=${(property as any)[formData.targetArea]}.
For disambiguation, the following JSON contains all the address information: 

JSON:
'''
${JSON.stringify(location)}
'''
`;
    }

    return completeText(`${promptInit}
${formData.customerType === 'generic' ? '' : `The text is targeting the following audience: ${formData.customerType}. When mentioning the audience, try to use synonyms to not overuse the same word.`}
Add a descriptive title at the top.
Use paragraphs to make the description more readable.
The text should have a length of approximately ${formData.textSize}.
The output should be in HTML format, don't include any CSS styles and use only the following tags: 
- h1
- p

HTML:`, 0.5)

  }
}