import * as React from "react";
import {
  CloseIcon,
  Button,
  styled,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@avenue-8/ui-2";
import { TextEditor, TextEditorProps } from "src/modules/shared/components/TextEditor";
import { DynamicDialog } from "src/modules/cma-v2/components/DynamicDialog";

interface EditCmaFormProps {
  open: boolean;
  onClose: () => void;
  defaultValues: {
    htmlContent: string;
  };
  onSave: (data: { htmlContent: string }) => void;
}

const CloseButton = styled(IconButton)`
  position: absolute;
  right: 8px;
  top: 12px;
`;

export const TextEditorModal = ({
  onSave,
  defaultValues,
  onClose,
  ...rest
}: EditCmaFormProps) => {
  const { htmlContent } = defaultValues;
  const [confirmCloseDialogOpen, setConfirmCloseDialogOpen] = React.useState(false);
  const editorRef: TextEditorProps["editorRef"] = React.useRef(null);

  const handleSave = () => {
    onSave({ htmlContent: editorRef.current?.getContent() ?? "" });
  };

  const handleCloseWithPrompt = async () => {
    const isDirty = Boolean(editorRef.current?.isDirty());
    if (isDirty) {
      setConfirmCloseDialogOpen(true);
      return;
    }
    setConfirmCloseDialogOpen(false);
    onClose();
  };

  const handleConfirmDialogClose = () => {
    setConfirmCloseDialogOpen(false);
    onClose();
  };

  const handleConfirmDialogSave = async () => {
    handleSave();
    setConfirmCloseDialogOpen(false);
    onClose();
  };

  return (
    <>
      <Dialog fullWidth maxWidth="md" {...rest} onClose={handleCloseWithPrompt} disableEnforceFocus>
        <DialogTitle id="editor-modal">
          Customize Text Section
          <CloseButton aria-label="close" onClick={handleCloseWithPrompt}>
            <CloseIcon />
          </CloseButton>
        </DialogTitle>
        <DialogContent style={{ minHeight: "500px" }}>
            <TextEditor htmlContent={htmlContent} editorRef={editorRef} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWithPrompt} variant="text">
            Cancel
          </Button>
          <Button autoFocus onClick={handleSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <DynamicDialog
        title={"Do you want to save?"}
        message={"You have made changes to this Text Section that have not been saved."}
        open={confirmCloseDialogOpen}
        actions={[
          {
            label: "No, discard",
            onClick: () => handleConfirmDialogClose(),
          },
          {
            label: "Save",
            onClick: async () => handleConfirmDialogSave(),
            primary: true,
          },
        ]}
      />
    </>
  );
};
