/* tslint:disable */
/* eslint-disable */
/**
 * Presentation API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AssistantPrompt,
    AssistantPromptFromJSON,
    AssistantPromptToJSON,
    AssistantPromptOutput,
    AssistantPromptOutputFromJSON,
    AssistantPromptOutputToJSON,
    FetchAllSectionsDataResponseDto,
    FetchAllSectionsDataResponseDtoFromJSON,
    FetchAllSectionsDataResponseDtoToJSON,
    FetchSectionsDataRequestDto,
    FetchSectionsDataRequestDtoFromJSON,
    FetchSectionsDataRequestDtoToJSON,
    FetchSectionsDataResponseDto,
    FetchSectionsDataResponseDtoFromJSON,
    FetchSectionsDataResponseDtoToJSON,
    GeneratePresentationPdfResponseDto,
    GeneratePresentationPdfResponseDtoFromJSON,
    GeneratePresentationPdfResponseDtoToJSON,
    PresentationConfigDto,
    PresentationConfigDtoFromJSON,
    PresentationConfigDtoToJSON,
    SharePresentationByEmailRequestDto,
    SharePresentationByEmailRequestDtoFromJSON,
    SharePresentationByEmailRequestDtoToJSON,
} from '../models';

export interface CMAPresentationControllerFetchAllSectionsDataRequest {
    id: string;
}

export interface CMAPresentationControllerFetchSectionsDataRequest {
    id: string;
    fetchSectionsDataRequestDto: FetchSectionsDataRequestDto;
}

export interface CMAPresentationControllerGeneratePdfUrlRequest {
    id: string;
    isAttachment?: any;
}

export interface CMAPresentationControllerGetAnswerFromAssistantRequest {
    assistantPrompt: AssistantPrompt;
}

export interface CMAPresentationControllerGetEditRequest {
    id: string;
}

export interface CMAPresentationControllerGetViewRequest {
    id: string;
}

export interface CMAPresentationControllerPatchConfigRequest {
    id: string;
    presentationConfigDto: PresentationConfigDto;
}

export interface CMAPresentationControllerSendShareEmailRequest {
    id: string;
    sharePresentationByEmailRequestDto: SharePresentationByEmailRequestDto;
}

/**
 * 
 */
export class CMAPresentationApi extends runtime.BaseAPI {

    /**
     * Force all sections to fetch data from the backend.
     */
    async cMAPresentationControllerFetchAllSectionsDataRaw(requestParameters: CMAPresentationControllerFetchAllSectionsDataRequest): Promise<runtime.ApiResponse<FetchAllSectionsDataResponseDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cMAPresentationControllerFetchAllSectionsData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cma-presentations/{id}/all-sections/data`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FetchAllSectionsDataResponseDtoFromJSON(jsonValue));
    }

    /**
     * Force all sections to fetch data from the backend.
     */
    async cMAPresentationControllerFetchAllSectionsData(requestParameters: CMAPresentationControllerFetchAllSectionsDataRequest): Promise<FetchAllSectionsDataResponseDto> {
        const response = await this.cMAPresentationControllerFetchAllSectionsDataRaw(requestParameters);
        return await response.value();
    }

    /**
     * Force some sections to fetch data from the backend.
     */
    async cMAPresentationControllerFetchSectionsDataRaw(requestParameters: CMAPresentationControllerFetchSectionsDataRequest): Promise<runtime.ApiResponse<FetchSectionsDataResponseDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cMAPresentationControllerFetchSectionsData.');
        }

        if (requestParameters.fetchSectionsDataRequestDto === null || requestParameters.fetchSectionsDataRequestDto === undefined) {
            throw new runtime.RequiredError('fetchSectionsDataRequestDto','Required parameter requestParameters.fetchSectionsDataRequestDto was null or undefined when calling cMAPresentationControllerFetchSectionsData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cma-presentations/{id}/sections/data`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FetchSectionsDataRequestDtoToJSON(requestParameters.fetchSectionsDataRequestDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FetchSectionsDataResponseDtoFromJSON(jsonValue));
    }

    /**
     * Force some sections to fetch data from the backend.
     */
    async cMAPresentationControllerFetchSectionsData(requestParameters: CMAPresentationControllerFetchSectionsDataRequest): Promise<FetchSectionsDataResponseDto> {
        const response = await this.cMAPresentationControllerFetchSectionsDataRaw(requestParameters);
        return await response.value();
    }

    /**
     * Generates a presentation PDF and returns the url to access it.
     */
    async cMAPresentationControllerGeneratePdfUrlRaw(requestParameters: CMAPresentationControllerGeneratePdfUrlRequest): Promise<runtime.ApiResponse<GeneratePresentationPdfResponseDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cMAPresentationControllerGeneratePdfUrl.');
        }

        const queryParameters: any = {};

        if (requestParameters.isAttachment !== undefined) {
            queryParameters['isAttachment'] = requestParameters.isAttachment;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cma-presentations/{id}/pdf-url`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GeneratePresentationPdfResponseDtoFromJSON(jsonValue));
    }

    /**
     * Generates a presentation PDF and returns the url to access it.
     */
    async cMAPresentationControllerGeneratePdfUrl(requestParameters: CMAPresentationControllerGeneratePdfUrlRequest): Promise<GeneratePresentationPdfResponseDto> {
        const response = await this.cMAPresentationControllerGeneratePdfUrlRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a answer from the AI assistant.
     */
    async cMAPresentationControllerGetAnswerFromAssistantRaw(requestParameters: CMAPresentationControllerGetAnswerFromAssistantRequest): Promise<runtime.ApiResponse<AssistantPromptOutput>> {
        if (requestParameters.assistantPrompt === null || requestParameters.assistantPrompt === undefined) {
            throw new runtime.RequiredError('assistantPrompt','Required parameter requestParameters.assistantPrompt was null or undefined when calling cMAPresentationControllerGetAnswerFromAssistant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cma-presentations/assistant/answer`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AssistantPromptToJSON(requestParameters.assistantPrompt),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AssistantPromptOutputFromJSON(jsonValue));
    }

    /**
     * Returns a answer from the AI assistant.
     */
    async cMAPresentationControllerGetAnswerFromAssistant(requestParameters: CMAPresentationControllerGetAnswerFromAssistantRequest): Promise<AssistantPromptOutput> {
        const response = await this.cMAPresentationControllerGetAnswerFromAssistantRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns the editable data for a PresentationView
     */
    async cMAPresentationControllerGetEditRaw(requestParameters: CMAPresentationControllerGetEditRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cMAPresentationControllerGetEdit.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cma-presentations/{id}/edit`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Returns the editable data for a PresentationView
     */
    async cMAPresentationControllerGetEdit(requestParameters: CMAPresentationControllerGetEditRequest): Promise<void> {
        await this.cMAPresentationControllerGetEditRaw(requestParameters);
    }

    /**
     * Returns a fake file. Only available on development.
     */
    async cMAPresentationControllerGetFileRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cma-presentations/file/fake/*`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Returns a fake file. Only available on development.
     */
    async cMAPresentationControllerGetFile(): Promise<void> {
        await this.cMAPresentationControllerGetFileRaw();
    }

    /**
     * Returns a single PresentationView
     */
    async cMAPresentationControllerGetViewRaw(requestParameters: CMAPresentationControllerGetViewRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cMAPresentationControllerGetView.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cma-presentations/{id}/view`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Returns a single PresentationView
     */
    async cMAPresentationControllerGetView(requestParameters: CMAPresentationControllerGetViewRequest): Promise<void> {
        await this.cMAPresentationControllerGetViewRaw(requestParameters);
    }

    /**
     * Updates a Presentation View
     */
    async cMAPresentationControllerPatchConfigRaw(requestParameters: CMAPresentationControllerPatchConfigRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cMAPresentationControllerPatchConfig.');
        }

        if (requestParameters.presentationConfigDto === null || requestParameters.presentationConfigDto === undefined) {
            throw new runtime.RequiredError('presentationConfigDto','Required parameter requestParameters.presentationConfigDto was null or undefined when calling cMAPresentationControllerPatchConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cma-presentations/{id}/edit`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PresentationConfigDtoToJSON(requestParameters.presentationConfigDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates a Presentation View
     */
    async cMAPresentationControllerPatchConfig(requestParameters: CMAPresentationControllerPatchConfigRequest): Promise<void> {
        await this.cMAPresentationControllerPatchConfigRaw(requestParameters);
    }

    /**
     */
    async cMAPresentationControllerSendShareEmailRaw(requestParameters: CMAPresentationControllerSendShareEmailRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cMAPresentationControllerSendShareEmail.');
        }

        if (requestParameters.sharePresentationByEmailRequestDto === null || requestParameters.sharePresentationByEmailRequestDto === undefined) {
            throw new runtime.RequiredError('sharePresentationByEmailRequestDto','Required parameter requestParameters.sharePresentationByEmailRequestDto was null or undefined when calling cMAPresentationControllerSendShareEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cma-presentations/{id}/share/email`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SharePresentationByEmailRequestDtoToJSON(requestParameters.sharePresentationByEmailRequestDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async cMAPresentationControllerSendShareEmail(requestParameters: CMAPresentationControllerSendShareEmailRequest): Promise<void> {
        await this.cMAPresentationControllerSendShareEmailRaw(requestParameters);
    }

}
