import {
  DefaultConfigBuilderContext,
  PresentationType,
  SectionViewBuilderContext,
  SectionWidget,
} from "../section-widget";
import { AIAssistedTextSectionConfig } from "./ai-assisted-text-section-config";
import { AIAssistedTextSectionView } from "./ai-assisted-text-section-view";
import { filterXSS } from "xss";
import { v4 as uuidv4 } from "uuid";

export class AIAssistedTextSectionWidget
  implements SectionWidget<"ai-assisted-text", AIAssistedTextSectionConfig, AIAssistedTextSectionView>
{
  type = "ai-assisted-text" as const;

  supportedPresentationTypes: PresentationType[] = ["cma"];

  buildDefaultConfig(_: DefaultConfigBuilderContext): AIAssistedTextSectionConfig {
    return {
      type: "ai-assisted-text",
      navTitle: "AI Assisted Text",
      id: uuidv4(),
      visibility: true,
      title: "Blank Section",
      htmlContent: `<h1 style="text-align:center;">Blank AI Section</h1><p style="text-align:center;">This is the new AI assisted text section.</p>`,
    };
  }

  sanitizeSectionConfig(sectionConfig: AIAssistedTextSectionConfig): AIAssistedTextSectionConfig {
    const { htmlContent } = sectionConfig;
    const sanitizedHtmlContent = filterXSS(htmlContent, {
      whiteList: {
        h1: ["style"],
        h2: ["style"],
        b: ["style"],
        p: ["style"],
        div: ["style"],
        strong: ["style"],
        em: ["style"],
        a: ["href", "target", "rel"],
        ul: ["style"],
        ol: ["style"],
        li: ["style"],
        br: [],
        i: ["style"],
        span: ["style"],
      },
    });
    return { ...sectionConfig, htmlContent: sanitizedHtmlContent };
  }

  buildView({
    sectionConfig: config,
  }: SectionViewBuilderContext<AIAssistedTextSectionConfig, null>): AIAssistedTextSectionView {
    if (config.type !== "ai-assisted-text") throw new Error("Unexpected section type.");
    const { id, type, visibility, htmlContent, title, navTitle } = config;
    const view: AIAssistedTextSectionView = {
      id,
      type,
      visibility,
      htmlContent,
      title,
      navTitle,
    };
    return view;
  }
}
