import { detailedDiff, diff } from "deep-object-diff";
import * as React from "react";
import { useIsMutating, useMutation, useQuery, useQueryClient } from "react-query";
import { useAppContext } from "../../../../../../AppContext";
import { toast } from "@avenue-8/ui-2";
import { buildPresentationView } from "../../../../../presentation/presentation-generation-logic/presentation-view-builder";
import { buildSectionView } from "../../../../../presentation/presentation-generation-logic/section-view-builder";
import {
  PresentationSourceData,
  PresentationView,
  SectionConfig,
} from "../../../../../presentation/presentation-generation-logic/";
import { getApiClient } from "../../../../../shared/apis/presentation-api-client/api-client-factory";
import { getCMAPresentationApi } from "../../../../../shared/apis/presentation/api-factories";
import { makeBLContext } from "../../../../../shared/hooks/makeBLContext";
import { useLatest } from "../../../../../shared/hooks/useLatest";
import { arrayMove } from "../../../../../shared/utils/array-move";
import { useDebounceFunction } from "../../../../hooks/useDebounceFunction";
import { appEventEmitter } from "../../../../../../events/app-event-emitter";
import { v4 as uuidv4 } from "uuid";
import { SectionData } from "src/modules/presentation/presentation-generation-logic/models/section-data/section-data";
import { PresentationSection } from "src/modules/presentation/presentation-generation-logic/models/section";
import { useSetEstimatePriceMutation } from "../Estimate/useEstimatePriceMutation";
import { sectionWidgetRegistry } from "src/modules/presentation/presentation-generation-logic/sections/section-widget-registry";
import { getNextTitleNumber } from "src/modules/shared/utils/get-next-title-number";
import { useListenPresentationSync } from "./useListenPresentationSync";

type SaveState = "saving" | "saved" | "failed" | "idle";
type DisplayMode = "responsive" | "mobile" | "desktop" | "tablet" | "print";
type SectionsDataState = "fetched" | "idle" | "failed";

interface NotLoadedState {
  loadState: "not-loaded";
  sectionsDataState: SectionsDataState;
  displayMode: DisplayMode;
}

interface FailedState {
  loadState: "failed";
  sectionsDataState: SectionsDataState;
  displayMode: DisplayMode;
}

export interface LoadedState {
  id: string;

  //server state
  sourceData: PresentationSourceData;
  sections: PresentationSection[];
  presentationView: PresentationView;

  //client state
  loadState: "loaded";
  sectionsDataState: SectionsDataState;
  displayMode: DisplayMode;
  isDirty: boolean;
  saveState: SaveState;

  templateId?: string | null;
}

type State = LoadedState | NotLoadedState | FailedState;

export function useLogic({ cmaId }: { cmaId: string }) {
  const { actions: appActions } = useAppContext();
  const [state, setState] = React.useState<State>({
    loadState: "not-loaded",
    displayMode: "desktop",
    sectionsDataState: "idle",
  });
  const lastState = useLatest(state);
  const saveWithDebounce = useDebounceFunction(save);
  const cmaPresentationApi = getCMAPresentationApi();
  const isMutating = useIsMutating();
  const queryClient = useQueryClient();

  async function save(initialState?: State) {
    const initState = initialState || lastState.current;
    if (initState.loadState !== "loaded") return;
    try {
      setState({ ...initState, saveState: "saving" });
      const { id, sections } = initState;
      const sectionsConfigs = sections.map((section) => section.config);

      await appActions.watchPromise(
        getApiClient().putPresentationConfig({ id, sections: sectionsConfigs }),
        {
          blocking: false,
          message: "Saving presentation...",
        }
      );
      setState({
        ...(lastState.current as LoadedState),
        saveState: "saved",
        isDirty: false,
        templateId: null,
      });
      appEventEmitter.emit({
        eventType: "presentation-saved",
        presentationType: initState.sourceData.presentationType,
      });
      resetPresentationPdf();
    } catch (e) {
      console.error(e);
      setState({ ...(lastState.current as LoadedState), saveState: "failed" });
    }
  }

  const loadPresentationQuery = useQuery(
    ["presentations", cmaId],
    () => getApiClient().getPresentationEdit(cmaId),
    {
      onSuccess: (data) => {
        const presentationView = buildPresentationView(data, true);

        //build state
        const { sourceData, sections } = data;
        const { displayMode } = state;

        const stateUpdate: LoadedState = {
          isDirty: false,
          saveState: "idle",
          id: cmaId,
          displayMode,
          loadState: "loaded",
          sections: sections,
          sourceData,
          presentationView,
          sectionsDataState: "idle",
        };
        const normalizedSections = normalizeSections({ sections, sourceData });
        setState({
          ...state,
          ...stateUpdate,
          sections: normalizedSections,
        });

        appEventEmitter.emit({
          eventType: "presentation-editor-loaded",
          presentationType: data.sourceData.presentationType,
        });
      },
      onError: (e) => {
        console.error(e);
        setState({
          loadState: "failed",
          displayMode: state.displayMode,
          sectionsDataState: "idle",
        });
      },
      retry: 5,
    }
  );

  const fetchAllSectionsData = React.useCallback(async () => {
    if (state.loadState !== "loaded") return;
    const { id, sections, sourceData } = state;

    const sectionsToUpdate = sections.filter((section) => {
      const widget = sectionWidgetRegistry.get(section.config.type);
      if (!widget) return false;
      return (
        section.data == null &&
        widget.canFetchData != null &&
        widget.canFetchData({
          config: section.config,
          presentationSourceData: sourceData,
        })
      );
    });

    if (sectionsToUpdate.length > 0 && state.sectionsDataState === "idle") {
      try {
        await cmaPresentationApi.cMAPresentationControllerFetchAllSectionsData({ id });
        queryClient.invalidateQueries(["presentations", cmaId]);
        setState({ ...state, sectionsDataState: "fetched" });
      } catch (error) {
        setState({ ...state, sectionsDataState: "failed" });
        console.error(error);
        throw error;
      }
    }

    return;
  }, [cmaId, cmaPresentationApi, queryClient, state]);

  const normalizeSections = (props: {
    sourceData: PresentationSourceData;
    sections: PresentationSection[];
  }) => {
    const { sourceData, sections } = props;
    const defineSectionDynamicDefaultValues = (section: SectionConfig) => {
      const newSection = { ...section };

      if (newSection.type === "header") {
        const priceRangeIsEmpty =
          sourceData.priceRange.max == null && sourceData.priceRange.min == null;
        newSection.showPriceRange =
          newSection.showPriceRange == null ? !priceRangeIsEmpty : newSection.showPriceRange;
        return newSection;
      }

      return newSection;
    };

    const sectionsWithDefaults = sections.map((x) => {
      const section = defineSectionDynamicDefaultValues(x.config);
      return { ...x, config: section };
    });

    return sectionsWithDefaults;
  };

  React.useEffect(() => {
    if (state.loadState === "loaded") {
      fetchAllSectionsData();
    }
  }, [fetchAllSectionsData, state.loadState]);

  const moveSection = (from: number, to: number) => {
    if (state.loadState !== "loaded") return;
    if (loadPresentationQuery.isFetching) return;
    if (from === to) return;
    const loadedState = { ...state } as LoadedState;
    const sections = arrayMove(loadedState.sections, from, to);
    const sectionType = sections[to].type;
    setState({
      ...state,
      sections,
      presentationView: {
        ...loadedState.presentationView,
        sections: arrayMove(loadedState.presentationView.sections, from, to),
      },
      isDirty: true,
    } as LoadedState);
    appEventEmitter.emit({
      eventType: "presentation-section-moved",
      from,
      to,
      sectionType,
      presentationType: state.sourceData.presentationType,
    });
    saveWithDebounce();
  };

  const setDisplayMode = (mode: DisplayMode) => {
    if (state.loadState !== "loaded") return;
    setState({ ...state, displayMode: mode });
    appEventEmitter.emit({
      eventType: "presentation-editor-display-mode-changed",
      mode,
      presentationType: state.sourceData.presentationType,
    });
  };

  const toggleSectionVisibility = (id: string) => {
    if (state.loadState !== "loaded") return;
    const section = state.sections.find((s) => s.config.id === id)!;
    const visibility = !(section?.config.visibility ?? true);
    setState({
      ...state,
      isDirty: true,
      sections: state.sections.map((s) =>
        s.config.id === id ? { ...s, config: { ...s.config, visibility } } : s
      ),
      presentationView: {
        ...state.presentationView,
        sections: state.presentationView.sections.map((s) =>
          s.id === id
            ? buildSectionView({
                sectionConfig: { ...section.config, visibility },
                sectionData: section.data,
                context: { editMode: true, sourceData: state.sourceData },
              })
            : s
        ),
      },
    });
    appEventEmitter.emit({
      eventType: visibility
        ? "presentation-section-view-enabled"
        : "presentation-section-view-disabled",
      sectionType: section.config.type,
      presentationType: state.sourceData.presentationType,
    });
    saveWithDebounce();
  };

  const setEstimatePriceMutation = useSetEstimatePriceMutation();

  const updateSection = (params: {
    sectionConfig: SectionConfig;
    partialSourceData?: {
      priceRange?: Partial<PresentationSourceData["priceRange"]>;
      coverPhotoCdnUrl?: PresentationSourceData["coverPhotoCdnUrl"];
    };
  }) => {
    if (state.loadState !== "loaded") throw new Error("Invalid call.");

    const { sectionConfig, partialSourceData } = params;
    const { sections } = state;
    const section = sections.find((s) => s.config.id === sectionConfig.id)!;
    const widget = sectionWidgetRegistry.get(section.config.type);
    const sanitizedSectionConfig =
      widget && widget.sanitizeSectionConfig
        ? widget.sanitizeSectionConfig(sectionConfig)
        : sectionConfig;
    const configDiff = diff(sanitizedSectionConfig, section.config);
    const hasSectionConfigChanges = Object.keys(configDiff).length > 0;
    const updatedSourceData = patchSourceDataState(state, partialSourceData);

    const sectionView = buildSectionView({
      sectionData: section.data,
      sectionConfig: sanitizedSectionConfig,
      context: { sourceData: updatedSourceData, editMode: true },
    });
    setState({
      ...state,
      isDirty: hasSectionConfigChanges ? true : state.isDirty,
      sections: sections.map((s) =>
        s.config.id === sanitizedSectionConfig.id ? { ...s, config: sanitizedSectionConfig } : s
      ),
      sourceData: updatedSourceData,
      presentationView: {
        ...state.presentationView,
        sections: state.presentationView.sections.map((s) =>
          s.id === sanitizedSectionConfig.id ? sectionView : s
        ),
      },
    });
    const change = detailedDiff(section.config, sanitizedSectionConfig);
    appEventEmitter.emit({
      eventType: "presentation-section-edited",
      sectionType: section.type,
      change,
      presentationType: state.sourceData.presentationType,
    });

    if (partialSourceData?.priceRange) {
      setEstimatePriceRange({
        priceRange: partialSourceData.priceRange,
        sourceId: state.sourceData.id,
        sectionId: sanitizedSectionConfig.id,
      });
    }

    const sectionWidget = sectionWidgetRegistry.get(sanitizedSectionConfig.type);

    const shouldInvalidateData = sectionWidget?.shouldInvalidateData?.({
      prevConfig: section.config,
      nextConfig: sanitizedSectionConfig,
      sectionData: section.data,
    });
    if (shouldInvalidateData) {
      if (
        !sectionWidget?.canFetchData ||
        !sectionWidget.canFetchData({
          config: sanitizedSectionConfig,
          presentationSourceData: state.sourceData,
        })
      ) {
        toast.error("Cannot fetch data for this section.");
        return;
      }

      fetchSectionsDataMutation.mutateAsync([
        {
          id: sanitizedSectionConfig.id,
          config: {
            ...sanitizedSectionConfig,
          },
        },
      ]);
      return;
    }

    if (hasSectionConfigChanges) saveWithDebounce();
  };

  const setEstimatePriceRange = (params: {
    sectionId: string;
    priceRange: Partial<PresentationSourceData["priceRange"]>;
    sourceId: PresentationSourceData["id"];
  }) => {
    const { priceRange, sourceId } = params;

    setEstimatePriceMutation.mutateAsync({
      min: priceRange.min,
      max: priceRange.max,
      eventOptions: {
        from: "presentation-sidebar",
      },
      cmaId: sourceId,
      disableInvalidateQueries: true,
    });
  };

  const applyTemplate = (sectionsConfigs: SectionConfig[]) => {
    updateSections(sectionsConfigs);
    appEventEmitter.emit({
      eventType: "presentation-template-selected",
      presentationType: (state as LoadedState).sourceData.presentationType,
    });
  };

  const updateSections = (sectionsConfigs: SectionConfig[]) => {
    if (state.loadState !== "loaded") throw new Error("Invalid call.");

    const { sourceData } = state;

    const sections: LoadedState["sections"] = sectionsConfigs.map((config) => ({
      type: config.type,
      config: { ...config, id: uuidv4() },
      data: null,
    }));

    const stateUpdate: LoadedState = {
      ...state,
      isDirty: true,
      sections,
      presentationView: buildPresentationView({ id: sourceData.id, sourceData, sections }, true),
      templateId: null,
    };

    save(stateUpdate);
  };

  const addNewSection = (sectionConfig: SectionConfig) => {
    if (state.loadState !== "loaded") return;
    const { sourceData, sections } = state;
    const section = {
      type: sectionConfig.type,
      config: { ...sectionConfig, id: uuidv4() },
      data: null,
    };
    const sectionsUpdate = [...sections, section];
    const stateUpdate: LoadedState = {
      ...state,
      isDirty: true,
      sections: sectionsUpdate,
      presentationView: buildPresentationView(
        { id: sourceData.id, sourceData, sections: sectionsUpdate },
        true
      ),
    };
    appEventEmitter.emit({
      eventType: "presentation-section-added",
      sectionType: sectionConfig.type,
      presentationType: (state as LoadedState).sourceData.presentationType,
    });
    save(stateUpdate);
  };

  const addTeamProfileSection = () => {
    const widget = sectionWidgetRegistry.get("team-profile");
    if (!widget) return;
    if (state.loadState !== "loaded") return;

    const teamProfileSection = state.sections.find((s) => s.config.type === "team-profile");
    if (teamProfileSection) {
      toast.error("You can only have one team profile section.");
      return;
    }

    addNewSection(widget.buildDefaultConfig({ sourceData: state.sourceData }));
  };

  const addFreeTextSection = () => {
    const widget = sectionWidgetRegistry.get("free-html-text");
    if (!widget) return;
    if (state.loadState !== "loaded") return;
    addNewSection(widget.buildDefaultConfig({ sourceData: state.sourceData }));
  };

  const addAIAssistedTextSection = () => {
    const widget = sectionWidgetRegistry.get("ai-assisted-text");
    if (!widget) return;
    if (state.loadState !== "loaded") return;
    addNewSection(widget.buildDefaultConfig({ sourceData: state.sourceData }));
  };

  const addInsightsSection = () => {
    if (state.loadState !== "loaded") return;
    const insightsSections = state.sections.filter((s) => s.config.type === "insights");

    let navTitle = "Insights";
    if (insightsSections.length > 0) {
      const newTitleNumber = getNextTitleNumber({
        titles: insightsSections.map((s) => s.config.navTitle),
        prefix: "#",
      });
      navTitle = `${navTitle} #${newTitleNumber}`;
    }

    addNewSection({
      type: "insights",
      id: uuidv4(),
      navTitle,
    });
  };

  const setTemplateId = (templateId?: string | null) => {
    if (state.loadState !== "loaded") throw new Error("Invalid call.");
    setState({ ...state, templateId });
  };

  const { mutateAsync: sharePresentationByEmail } = useMutation(
    async ({ emails, agentComment }: { emails: string[]; agentComment?: string }) =>
      await appActions.watchPromise(
        cmaPresentationApi.cMAPresentationControllerSendShareEmail({
          id: cmaId,
          sharePresentationByEmailRequestDto: {
            emails,
            agentComment,
          },
        }),
        {
          blocking: true,
          message: "Sending email(s)...",
        }
      ),
    {
      onSuccess: () => {
        appEventEmitter.emit({
          eventType: "presentation-share-email-sent",
          presentationType: (state as LoadedState).sourceData.presentationType,
        });
      },
      onError: (e) => {
        console.error(e);
        toast.error("Failed to send email(s).");
      },
    }
  );

  const deleteSection = async (id: string) => {
    if (state.loadState !== "loaded") return;

    const deleteConfirmed = await appActions.confirm({
      title: "Delete Section",
      message: "Are you sure you want to delete this section? This action is irreversible.",
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
    });

    if (!deleteConfirmed) return;

    const { sections } = state;
    const sectionsUpdate = sections.filter((s) => s.config.id !== id);
    const stateUpdate: LoadedState = {
      ...state,
      isDirty: true,
      sections: sectionsUpdate,
      presentationView: {
        ...state.presentationView,
        sections: state.presentationView.sections.filter((s) => s.id !== id),
      },
    };
    appEventEmitter.emit({
      eventType: "presentation-section-removed",
      sectionType: sections.filter((s) => s.config.id === id)[0].config.type,
      presentationType: (state as LoadedState).sourceData.presentationType,
    });
    setState(stateUpdate);
    save(stateUpdate);
  };

  const {
    mutateAsync: generatePresentationPdf,
    data: presentationPdfData,
    reset: resetPresentationPdf,
  } = useMutation(
    async ({ isAttachment }: { isAttachment?: boolean }) =>
      appActions.watchPromise(
        cmaPresentationApi.cMAPresentationControllerGeneratePdfUrl({
          id: cmaId,
          isAttachment,
        }),
        {
          blocking: true,
          message: "Generating PDF...",
        }
      ),
    {
      onError: (e) => {
        console.error(e);
        toast.error("Failed to generate the PDF.");
      },
    }
  );

  /**
   * Created this method to handle multiple sections at once as the fetchSectionsDataMutation handles.
   */
  const patchSectionsState = (
    state: LoadedState,
    sectionsToPatch: Array<{
      id: string;
      data?: Partial<SectionData>;
      config?: Partial<SectionConfig>;
    }>
  ): LoadedState => {
    const {
      sections,
      sourceData,
      presentationView: { sections: sectionViews },
    } = state;

    const patchedSections = new Map<string, PresentationSection>();
    const newSections = sections.map((section) => {
      const sectionToPatch = sectionsToPatch.find((s) => s.id === section.config.id);
      if (!sectionToPatch) return section; //there is no patch for this section - return it as it is

      const { data, config } = sectionToPatch;
      const updatedSection: PresentationSection = {
        ...section,
        data: data ? { ...section.data, ...data } : section.data,
        config: config ? { ...section.config, ...(config as any) } : section.config,
      };
      patchedSections.set(updatedSection.config.id, updatedSection as PresentationSection);
      return updatedSection;
    });

    const newSectionViews = sectionViews.map((sectionView) => {
      const patchedSection = patchedSections.get(sectionView.id);
      if (!patchedSection) return sectionView; //return the same because it has not been patched

      return buildSectionView({
        context: { editMode: true, sourceData },
        sectionConfig: patchedSection.config,
        sectionData: patchedSection.data,
      });
    });

    return {
      ...state,
      sections: newSections,
      presentationView: {
        ...state.presentationView,
        sections: newSectionViews,
      },
      isDirty: false,
    };
  };

  const patchSourceDataState = (
    state: LoadedState,
    partialSourceData?: {
      priceRange?: Partial<PresentationSourceData["priceRange"]>;
      coverPhotoCdnUrl?: PresentationSourceData["coverPhotoCdnUrl"];
    }
  ): PresentationSourceData => {
    if (!partialSourceData) return state.sourceData;
    const newSourceData = Object.assign({}, state.sourceData);

    if (partialSourceData.priceRange !== undefined) {
      newSourceData.priceRange = {
        ...state.sourceData.priceRange,
        ...partialSourceData.priceRange,
      };
    }

    if (partialSourceData.coverPhotoCdnUrl !== undefined) {
      newSourceData.coverPhotoCdnUrl = partialSourceData.coverPhotoCdnUrl;
    }

    return newSourceData;
  };

  const fetchSectionsDataMutation = useMutation(
    ["fetch-presentation-sections-data"],
    async (sections: { id: SectionConfig["id"]; config: SectionConfig }[]) =>
      cmaPresentationApi.cMAPresentationControllerFetchSectionsData({
        id: cmaId ?? "",
        fetchSectionsDataRequestDto: {
          sections,
        },
      }),
    {
      onSuccess: (data, sections) => {
        const updateState = patchSectionsState(
          state as LoadedState,
          data.fetchedData.map((view) => ({
            id: view.id,
            config: sections.find((x) => x.id === view.id)?.config as SectionConfig,
            data: view.data,
          }))
        );
        setState(updateState);
        toast.success(`Presentation updated successfully.`, {
          shouldDeduplicate: true,
        });
        const sectionsTitles = sections.map((x) => x.config.navTitle);
        appEventEmitter.emit({
          eventType: "presentation-sections-data-fetched",
          sections: sectionsTitles,
          presentationType: (state as LoadedState).sourceData.presentationType,
        });
      },
      onError: (e) => {
        console.error(e);
        throw e;
      },
    }
  );

  const isSaving = state.loadState === "loaded" && (state.isDirty || isMutating > 0);

  useListenPresentationSync(() => {
    setTimeout(() => {
      queryClient.invalidateQueries(["presentations", cmaId]);
    }, 2000);
  });

  return {
    state: {
      ...state,
      presentationPdfData,
      isSaving,
    },
    actions: {
      moveSection,
      save,
      setDisplayMode,
      toggleSectionVisibility,
      updateSection,
      applyTemplate,
      setTemplateId,
      sharePresentationByEmail,
      addTeamProfileSection,
      addFreeTextSection,
      addInsightsSection,
      addAIAssistedTextSection,
      deleteSection,
      generatePresentationPdf,
      fetchSectionsDataMutation,
    },
  };
}

export const {
  LogicContextProvider: PresentationEditorLogicProvider,
  useLogicContext: usePresentationEditorLogic,
} = makeBLContext({ useLogic });
