export function addEmptyParagraphsBetweenConsecutiveParagraphs(html: string) {
  return html.replace(/<\/p>\s*<p>/g, "</p><p><br></p><p>");
}

export function centralizeFirstHeader(html: string) {
  return html.replace(/<h1>/, "<h1 style='text-align: center'>");
}

export function sanitizeOpenAIHtmlOutput(html: string) {
  return filterXSS(html, {
    whiteList: {
      h1: ["style"],
      h2: ["style"],
      b: ["style"],
      p: ["style"],
      div: ["style"],
      strong: ["style"],
      em: ["style"],
      a: ["href", "target", "rel"],
      ul: ["style"],
      ol: ["style"],
      li: ["style"],
      br: [],
      i: ["style"],
      span: ["style"],
    },
  });
}