import { InputLabel, Select } from "@mui/material";
import { AITextGenerator, AITextGeneratorForm } from "../ai-text-generator";
import { useState } from "react";
import { SelectOption } from "@avenue-8/ui-2";

interface FormValues {
  numberOfParagraphs: string;
}

const Form: AITextGeneratorForm<FormValues> = ({
  onFormChanged,
  formInitState
}) => {

  const [formValues, setFormValues] = useState<FormValues>(formInitState);

  const handleInputChange = (event: { target: { name: string, value: string } }) => {
    const { name, value } = event.target;
    const newFormValues = {
      ...formValues,
      [name as string]: value,
    };
    setFormValues(newFormValues);
    onFormChanged(newFormValues);
  }

  return (<>
    <InputLabel>Content Size</InputLabel>
    <Select
      name="numberOfParagraphs"
      value={formValues.numberOfParagraphs}
      label="Content Size"
      onChange={handleInputChange}
    >
      <SelectOption value={"free"}>Choose for me</SelectOption>
      <SelectOption value={1}>Concise</SelectOption>
      <SelectOption value={2}>Small</SelectOption>
      <SelectOption value={3}>Medium</SelectOption>
      <SelectOption value={4}>Large</SelectOption>
    </Select>
  </>);
}

export const subjectPropertyDescriptionGenerator: AITextGenerator<FormValues> = {
  title: 'Subject Property Description Generator',
  key: 'subject-property',
  description: 'Generate a descriptive summary of the subject property using the available property data. Use unstructured and structured data from the property to generate the text.',
  formInitState: {
    numberOfParagraphs: "free"
  },
  Form,
  validateRequirements: (context) => {
    if (context.source.presentationType !== "cma") {
      return "Your presentation is not a CMA.";
    }
    return null;
  },
  generate: async ({ completeText, context, formData }) => {
    const property = context.source.featuredProperty;
    delete property.photos;
    return completeText(`The following JSON contains data from a listing (real estate).

JSON:
'''
${JSON.stringify(property)}
'''

Using the information above, write a technical ${formData.numberOfParagraphs !== "free" ? 100 * parseInt(formData.numberOfParagraphs) : 200}-words description of the property.
${formData.numberOfParagraphs !== "free" ? `Use ${formData.numberOfParagraphs} paragraphs.` : ''}
Your mission is to help someone to decide if the property is a good fit for them.
Add a descriptive title at the top too.
Use paragraphs to make the description more readable.
The output should be in HTML format, don't include any CSS styles and use only the following tags: 
- h1
- p

HTML:`, 0.5)

  }
}