import React from "react";
import NumberFormat from "react-number-format";
import { Controller, useFormContext } from "react-hook-form";
import { Select, SelectOption, InputLabel } from "@avenue-8/ui-2";
import { TextFieldBase } from "../../../../../TextFieldBase";
import { MinMaxField } from "../../../../../Fields/MinMaxField";
import { CheckboxSetField } from "../../../../../Fields/CheckboxSetField";
import {
  rangeDatesListingStatusOptions,
  SearchComparableFormModel,
} from "../../search-comparable-form-model";
import { appEventEmitter } from "../../../../../../../../events/app-event-emitter";
import { useNativeFormInputListener } from "../../../../../../../shared/hooks/useNativeFormInputListener";
import { MlsAreaField } from "../../../../../Fields/MlsAreaField";
import { NeighborhoodField } from "../../../../../Fields/NeighborhoodField";
import { SmartSwitch } from "../SmartSwitch";
import { usePropertyTypeQueries } from "src/modules/cma-v2/queries/usePropertyTypeQueries";
import { ToggleButtonGroup } from "@mui/material";
// TODO: Move ToggleButton to av8-ui-2
import { ToggleButton } from "src/modules/shared/components/ToggleButton";
import {
  Form,
  FieldsContainer,
  Divider,
  ContainerColumn,
  FilterLabel,
  SelectSpacer,
  FieldSpace,
  FieldItem,
} from "./SearchFiltersForm.styles";
import { RadiusField } from "src/modules/cma-v2/components/Fields/RadiusField";
import { MAX_INTEGER_SMALL_VALUE, MAX_INTEGER_VALUE } from "src/modules/shared/constants";
interface Props {
  onApplyFilters: (data: SearchComparableFormModel) => void;
  onClose: () => void;
  presentationType: string | undefined;
  radiusFilterCoords:
    | {
        lat: number;
        lng: number;
      }
    | undefined;
}

const applyPropertyTypesMap = (params: { label: string; value: number }) => {
  const { label, value } = params;
  const mappedPropertyTypes: Record<string, string> = {
    manufacturedinpark: "Manufactured in Park",
  };
  return { label: mappedPropertyTypes[label.toLowerCase()] ?? label, value };
};

export const SearchFiltersForm = (props: Props) => {
  const { onApplyFilters, onClose, presentationType, radiusFilterCoords } = props;
  const { handleSubmit, setValue } = useFormContext();
  const { propertyTypesQuery } = usePropertyTypeQueries();
  const cachedPropertyTypes = propertyTypesQuery.data?.propertyTypes || [];
  const propertyTypesOptions = cachedPropertyTypes
    .map((x) => applyPropertyTypesMap({ label: x.propertyType, value: x.id }))
    .sort((a, b) => (a.label > b.label ? 1 : -1))
    .filter((t) => t.label?.trim());

  const radiusDescription =
    presentationType === "cma"
      ? "Radius searches around the subject property"
      : "Radius searches around your current location";

  const handleApplyFilters = (data: SearchComparableFormModel) => {
    const parsedData: SearchComparableFormModel = {
      ...data,
      soldLastDays: data.sold ? data.soldLastDays || "all-time" : "",
      listLastDays: data.active ? data.listLastDays || "all-time" : "",
    };
    onClose();
    onApplyFilters(parsedData);
  };

  useNativeFormInputListener({
    id: "search-step-filters",
    onNativeInputChange: (filter, value) => {
      appEventEmitter.emit({
        eventType: "cma-search-filter-changed",
        filter,
        value,
        presentationType: presentationType!,
      });
    },
  });

  const isAllowedLargeNumber = React.useCallback(
    ({ floatValue }: { floatValue: number | undefined }) =>
      Number(floatValue ?? 0) <= MAX_INTEGER_VALUE,
    []
  );

  const isAllowedSmallNumber = React.useCallback(
    ({ floatValue }: { floatValue: number | undefined }) =>
      Number(floatValue ?? 0) <= MAX_INTEGER_SMALL_VALUE,
    []
  );

  return (
    <Form id="search-step-filters" onSubmit={handleSubmit(handleApplyFilters)}>
      <FieldsContainer>
        <Controller
          name="businessType"
          render={(props) => (
            <ToggleButtonGroup {...props} color="primary" exclusive size="small">
              <ToggleButton value="sale">For Sale</ToggleButton>

              <ToggleButton value="rent">For Rent</ToggleButton>
            </ToggleButtonGroup>
          )}
        />
      </FieldsContainer>
      <Divider $type="row" />
      <FieldsContainer>
        <ContainerColumn>
          <FilterLabel>Listing Status</FilterLabel>
          <CheckboxSetField
            fieldName="sold"
            options={[{ label: "Sold/Rented", value: "sold" }]}
            checkboxProps={{
              onChange: (e) => setValue("soldLastDays", e.target.checked ? "all-time" : ""),
            }}
          />
          <SelectSpacer>
            <Controller
              name="soldLastDays"
              render={(props) => (
                <Select
                  {...props}
                  onChange={(e) => {
                    appEventEmitter.emit({
                      eventType: "cma-search-filter-changed",
                      filter: "soldLastDays",
                      value: e.target.value,
                      presentationType: presentationType!,
                    });
                    props.onChange(e);
                    if (e.target.value) setValue("sold", "sold");
                  }}
                >
                  {rangeDatesListingStatusOptions.map((rangeOption) => (
                    <SelectOption key={rangeOption.label} value={rangeOption.value}>
                      {rangeOption.label}
                    </SelectOption>
                  ))}
                </Select>
              )}
            />
          </SelectSpacer>

          <CheckboxSetField
            fieldName="active"
            options={[{ label: "Active", value: "active" }]}
            checkboxProps={{
              onChange: (e) => setValue("listLastDays", e.target.checked ? "all-time" : ""),
            }}
          />
          <SelectSpacer>
            <Controller
              name="listLastDays"
              render={(props) => (
                <Select
                  {...props}
                  onChange={(e) => {
                    appEventEmitter.emit({
                      eventType: "cma-search-filter-changed",
                      filter: "listLastDays",
                      value: e.target.value,
                      presentationType: presentationType!,
                    });
                    props.onChange(e);
                    if (e.target.value) setValue("active", "active");
                  }}
                >
                  {rangeDatesListingStatusOptions.map((rangeOption) => (
                    <SelectOption key={rangeOption.label} value={rangeOption.value}>
                      {rangeOption.label}
                    </SelectOption>
                  ))}
                </Select>
              )}
            />
          </SelectSpacer>
          <CheckboxSetField
            fieldName="pending"
            options={[{ label: "Pending", value: "pending" }]}
          />
          <CheckboxSetField
            fieldName="contingent"
            options={[{ label: "Contingent", value: "contingent" }]}
          />
          <FilterLabel>Property Types</FilterLabel>
          <CheckboxSetField fieldName="propertyTypes" options={propertyTypesOptions} columns={2} />
          <FilterLabel>Other Property Details</FilterLabel>
          <FieldSpace>
            <InputLabel>Doorman/attendance</InputLabel>
            <Controller
              name="doorman"
              render={(props) => (
                <Select
                  {...props}
                  onChange={(e) => {
                    appEventEmitter.emit({
                      eventType: "cma-search-filter-changed",
                      filter: "doorman",
                      value: e.target.value,
                      presentationType: presentationType!,
                    });
                    props.onChange(e);
                  }}
                  data-testid="doorman-filter"
                >
                  <SelectOption value="">&nbsp;</SelectOption>
                  <SelectOption value="yes">Yes</SelectOption>
                  <SelectOption value="no">No</SelectOption>
                </Select>
              )}
            />
            <MlsAreaField showFixedErrorMessage />
          </FieldSpace>
          <FieldItem>
            <NeighborhoodField showFixedErrorMessage />
          </FieldItem>
          <FieldItem>
            <RadiusField
              showFixedErrorMessage
              step={0.25}
              coords={radiusFilterCoords}
              description={radiusDescription}
            />
          </FieldItem>
        </ContainerColumn>
        <Divider $type="column" />
        <ContainerColumn>
          <FilterLabel>Price</FilterLabel>
          <MinMaxField
            fieldName="price"
            component={NumberFormat}
            fieldProps={{
              customInput: TextFieldBase,
              thousandSeparator: true,
              isAllowed: isAllowedLargeNumber,
              allowNegative: false,
            }}
          />
          <FilterLabel>Beds</FilterLabel>
          <MinMaxField
            fieldName="beds"
            component={NumberFormat}
            fieldProps={{
              customInput: TextFieldBase,
              isAllowed: isAllowedSmallNumber,
              allowNegative: false,
            }}
          />
          <FilterLabel>Baths</FilterLabel>
          <MinMaxField
            fieldName="baths"
            component={NumberFormat}
            fieldProps={{
              customInput: TextFieldBase,
              isAllowed: isAllowedSmallNumber,
              allowNegative: false,
            }}
          />
          <FilterLabel>Garage Space(s)</FilterLabel>
          <MinMaxField
            fieldName="garageSpaces"
            component={NumberFormat}
            fieldProps={{
              customInput: TextFieldBase,
              isAllowed: isAllowedSmallNumber,
              allowNegative: false,
            }}
          />
          <FilterLabel>Living Space</FilterLabel>
          <MinMaxField
            fieldName="livingSpace"
            component={NumberFormat}
            fieldProps={{
              customInput: TextFieldBase,
              thousandSeparator: true,
              isAllowed: isAllowedLargeNumber,
              allowNegative: false,
            }}
          />
          <FilterLabel>Lot Size</FilterLabel>
          <MinMaxField
            fieldName="lotSize"
            component={NumberFormat}
            fieldProps={{
              customInput: TextFieldBase,
              thousandSeparator: true,
              isAllowed: isAllowedLargeNumber,
              allowNegative: false,
            }}
          />
          <FilterLabel>Year Built</FilterLabel>
          <MinMaxField
            fieldName="yearBuilt"
            component={NumberFormat}
            fieldProps={{ customInput: TextFieldBase, isAllowed: isAllowedLargeNumber }}
          />
          <SmartSwitch
            name={"smartSearch"}
            label={"Smart Search"}
            description={`This will include properties that may not fit all of your criteria but we think may still
            be useful for your search.`}
            showIcon
          />
          {presentationType === "cma" && (
            <SmartSwitch
              name={"sameBuildingSearch"}
              label={"Same Building Search"}
              description={"Only show units within the same building as subject property"}
            />
          )}
        </ContainerColumn>
      </FieldsContainer>
    </Form>
  );
};
