/* tslint:disable */
/* eslint-disable */
/**
 * Presentation API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AssistantPrompt
 */
export interface AssistantPrompt {
    /**
     * 
     * @type {string}
     * @memberof AssistantPrompt
     */
    prompt: string;
    /**
     * 
     * @type {number}
     * @memberof AssistantPrompt
     */
    temperature: number;
}

export function AssistantPromptFromJSON(json: any): AssistantPrompt {
    return AssistantPromptFromJSONTyped(json, false);
}

export function AssistantPromptFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssistantPrompt {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'prompt': json['prompt'],
        'temperature': json['temperature'],
    };
}

export function AssistantPromptToJSON(value?: AssistantPrompt | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'prompt': value.prompt,
        'temperature': value.temperature,
    };
}


