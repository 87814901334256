import * as React from "react";
import {
  PropertyPhoto,
  SectionView as SectionViewModel,
} from "../../../../presentation/presentation-generation-logic/models";
import { FreeText } from "./FreeText";
import { HeroHeader, GeneralHeroHeader } from "./HeroHeader";
import { Insights } from "./Insights";
import { KeyFeatures } from "./KeyFeatures";
import { NeighborhoodMap } from "./NeighborhoodMap";
import { SideBySideComparison } from "./SideBySideComparison";
import { FreeHtmlText } from "./FreeHtmlText";
import { TeamProfile } from "./TeamProfile";
import NotImplemented from "./NotImplemented";

interface Props {
  section: SectionViewModel;
  displayMode: "desktop" | "mobile" | "print" | "tablet";
  galleries: { [key: string]: PropertyPhoto[] };
  editMode?: boolean;
}

export const SectionView = React.memo(({ section, displayMode, galleries, editMode }: Props) => {
  /*
  This React.memo greatly improves performance of the presentation
  because the section views will only render again when a section changes
  */

  const mobile = displayMode === "mobile";
  switch (section.type) {
    case "key-features": {
      return <KeyFeatures {...section} />;
    }
    case "insights": {
      return <Insights mobile={mobile} {...section} editMode={editMode} />;
    }
    case "neighborhood-map": {
      return <NeighborhoodMap {...section} galleries={galleries} />;
    }
    case "side-by-side-comparision": {
      return <SideBySideComparison {...section} galleries={galleries} displayMode={displayMode} />;
    }
    case "free-text": {
      return <FreeText {...section} />;
    }
    case "ai-assisted-text": {
      // it is the same as free-html-text
      return <FreeHtmlText {...section} />;
    }
    case "header": {
      return <HeroHeader {...section} displayMode={displayMode} />;
    }
    case "general-header": {
      return <GeneralHeroHeader {...section} displayMode={displayMode} />;
    }
    case "agent-info": {
      return null; // TODO: implement agent info section
    }
    case "free-html-text": {
      return <FreeHtmlText {...section} />;
    }
    case "team-profile": {
      return <TeamProfile {...section} />;
    }
    default: {
      return <NotImplemented />;
    }
  }
});
