import { ReactElement, useEffect, useRef, useState } from "react";

type TimerStatus = "running" | "stopped";

export const Timer = function ({ render, tick, status }: {
  render: (time: number, status: TimerStatus) => ReactElement,
  tick: number,
  status: TimerStatus,
}) {
  const [time, setTime] = useState(0);
  const lastTime = useRef(0);
  lastTime.current = time;

  useEffect(() => {
    if (status === "running") {
      if (lastTime.current !== 0) setTime(0);
      const interval = setInterval(() => {
        setTime((time) => time + tick);
      }, tick);
      return () => {
        clearInterval(interval);
      };
    }
  }, [status, tick]);

  return render(time, status);
}