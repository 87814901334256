import { Avatar, Typography, IconButton } from "@avenue-8/ui-2";
import { Close, MoreVert } from "@mui/icons-material";
import * as React from "react";
import { styledThemed as styled } from "../theme";

interface Props {
  agentName: string;
  agentPhotoSrc?: string;
  agentLineInfo?: string;
  onOptionsClick: (e: React.SyntheticEvent<any>) => void;
  optionsDisplayIcon?: "more" | "close";
}

const rightContentWidth = "8rem";

const InternalLayout = styled.div`
  position: relative;
  height: 4rem;
  overflow: hidden;
  display: flex;
  align-items: center;
`;

const StyledAvatar = styled(Avatar)`
  width: 4rem;
  height: 4rem;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0 12px;
`;

const AgentName = styled(Typography)`
  font-family: ${(p) => p.theme.presentation.headerFontFamily};
  font-size: 1.2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const AgentLine = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const RightContent = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 4rem;
  width: ${() => rightContentWidth};
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
`;

const JumpTo = styled(Typography)`
  white-space: nowrap;
  font-size: 0.8rem;
`;

export const FloatingAgentBanner = ({
  agentName,
  agentPhotoSrc,
  agentLineInfo,
  onOptionsClick,
  optionsDisplayIcon,
}: Props) => {
  return (
    <InternalLayout>
      {!!agentPhotoSrc && <StyledAvatar alt={agentName} src={agentPhotoSrc} />}
      <Content>
        <AgentName>{agentName}</AgentName>
        {agentLineInfo && <AgentLine>{agentLineInfo}</AgentLine>}
      </Content>
      <RightContent>
        <JumpTo color="primary">{optionsDisplayIcon === "close" ? "" : "JUMP TO"}</JumpTo>
        <IconButton color="primary" onClick={onOptionsClick}>
          {optionsDisplayIcon === "close" ? <Close /> : <MoreVert />}
        </IconButton>
      </RightContent>
    </InternalLayout>
  );
};
